header.secondary {
	border-bottom: 1px solid $grey-n08;
	background-color: $grey-n00;

	.width-constraint {
		@include flexbox;
	}

	a.go-back {
		@include flexbox;
		@include align-items(center);
		@include order(0);

		min-width: 1.5em;

		.icon {
			width: 1.5em;
			height: 1.5em;
		}

		@include min-screen-size(large) {
			display: none;
		};
	}

	h1:not(.page-title) {
		position: relative;
		left: 0;

		@include flex(1,0,auto);
		@include order(1);

		margin: 0;

		@include line-height-multiplier(larger, 1.382);
		color: $grey-n10;
		text-align: center;
		@include ellipsis;

		@include min-screen-size(large) {
			left: .382em;
			@include flex(0,1,auto);
			text-align: left;
		}
	}

	@include min-screen-size(large) {
		background-color: transparent;
		border-color: $grey-n03;
	}
}
