$ada-color: #df7934;

.ada-warning {
	position: relative;
	overflow: visible !important;
	text-decoration: none;
	
	outline: {
		width: 5px !important;
		style: solid !important;
		color: $ada-color !important;
		offset: -3px !important;
	}
	
	&:after {
		position: absolute;
		right: -3px;
		bottom: 100%;
		
		display: block;
		max-width: 100%;
		padding: 0 .618em;
		
		@include font-size(small);
		color: $white;
		
		background: $ada-color;
		border-radius: $default-border-radius $default-border-radius 0 0;
	}
	
	&.missing-alt-text:after {
		content: 'Missing alt text!';
		line-height: 2em;
	}

	&.placeholder-alt-text:after {
		content: 'Placeholder alt text';
		line-height: 2em;
	}
}