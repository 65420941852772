// PARTIAL : CMS Header

// Import Standard Tools
@import 'global/feature-tools';

// Header height is fluid, so we need to specify line-heights in several places for different elements
// in the header. This mixin allows us to avoid repetition of code. Variables are set in _edlio.scss
@mixin header-line-height {
	@include override-line-height($header-default-font-size, $header-line-height-multiplier);

	@include min-screen-size(huge) {
		line-height: $header-line-height-multiplier-huge * get-line-height($header-default-font-size, huge);
	}
}

header.main {
	@import 'components/tooltips';

	a:first-child{
		.school-name{
			color: white;
		}
		&:hover, &:focus{
			.school-name{
				color: $edlio-red;
			}
		}
	}

	position: fixed;
	width: 100%;
	z-index: $z-index-header;

	background: rgb(34,34,34);
	border-bottom: $header-border-width solid $header-border-color;
	box-shadow: 0 3px 8px 0 $default-drop-shadow-color;
	font-weight: normal;
	font-family: "Open Sans", Helvetica, Arial, sans-serif !important;

	color: $header-text-color;

	.width-constraint {
		@include flexbox;
		@include flex(1, 0, auto);
		@include align-items(center);
		position: relative;

		@include max-screen-size(small) {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}

	a {
		color: $header-text-color;
		text-decoration: none;
	}

	.hamburger {
		// flex-shrink must be 0 to avoid shrinking below content-width in Chrome
		@include flex(0, 0, auto);
		@include order(0);

		// this is for IE which doesn't expand to the whole height
		display: block;

		margin-right: .382em;
		padding: 0 .382em;

		@include min-screen-size(medium) {
			padding: 0 .618em;
		}

		text-decoration: none;
		color: $header-link-color;

		@include header-line-height;

		cursor: pointer;

		svg.icon {
			fill: $header-dropdown-color;
			vertical-align: middle;
			height: 1.5em;
			width: 1.5em;
		}

		svg.perl {
			height: 27px;
			width: 27px;
		}

		&:hover,
		&:active,
		&:focus {
			background: $header-dropdown-color;
			color: $dropdown-hover-color;
			svg.icon {
				fill: $dropdown-hover-color;
			}
		}
	}

	h1.school-name {
	    font-weight: 400;
	    min-width: 4em;

		@include order(1);

		margin: 0;

		@include font-size(large);
		@include header-line-height;
		@include ellipsis;

		> a {
			@include font-size(large);
			color: $header-link-color;
			text-decoration: none;
		}

		svg {
			fill: $white;
		}
	}

	a.add-school {
		@include order(2);

		margin: 0;
		padding: 0 .618em;

		svg.icon {
			fill: $header-dropdown-color;
			vertical-align: middle;
			height: 1.5em;
			width: 1.5em;
		}
	}

	nav {
		@include flexbox;
		// flex-shrink must be 0 to avoid shrinking below content-width in Chrome
		@include flex(1, 0, auto);
		@include justify-content(flex-end);
		@include order(3);

		@include override-line-height(large, 2, true);

		@include min-screen-size(huge) {
			line-height: 1.618 * get-line-height(large, huge);
			height: 1.618 * get-line-height(large, huge);
		}

		&.school-name {
			display: block;

			svg.icon {
				position: relative;
				pointer-events: none;
				vertical-align: middle;
			}

			.manage-website.item {
				border-top: 1px solid $grey-3;
			}


			.dropdown .menu a {
				color: $grey-6;
			}

			@include max-screen-size(medium) {
				flex: 1 10 4em;
				width: auto;
				min-width: 4em;
			}

		}

		a {
			padding: 0 0.8em;

			@include font-size(normal);

			color: $header-link-color;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}

			@include header-line-height;
		}

		.dropdown {
			position: relative;

			@include flex(0, 1, auto);
			@include flex-direction(column);

			margin-bottom: 0;
			cursor: pointer;
			header >a{
				color: white;

			}
			h1{
				color: inherit;
				font-weight: normal;
			}
			&:hover, &:focus{
				header > a{
					color: $edlio-red;
				}
			}

			a {
				margin-left: 0;
			}

			header {
				@include flexbox;
				@include align-items(center);

				padding: 0 .618em;
				cursor: default;

				@include font-size(normal);
				@include override-line-height(large, 2, true);

				a {
					@include flexbox;
					@include align-items(center);
					padding: 0;
					cursor: default;
					color: $header-link-color;
				}

				span {
					@include hide;
					color: $header-link-color;

					@include min-screen-size(medium) {
						@include show;
					}
				}

				svg.icon {
					height: 1.5em;
					width: 1.5em;

					@include header-line-height;

					fill: $header-link-color;

					@include min-screen-size(medium) {
						margin-right: .618em;
					}
				}

				.profile-photo {
					width: 2em;
					height: 2em;
					border-radius: 50%;
					margin: 0 .618em 0 .382em;
				}

				&:after {
					content: '\25BC';

					@include hide;
					margin-left: .618em;

					font-size: .618em;

					@include min-screen-size(medium) {
						@include show;
					}
				}

				@include min-screen-size(huge) {
					line-height: 1.618 * get-line-height(large, huge);
					height: 1.618 * get-line-height(large, huge);
				}

				.desktop-header {
					padding-right: 0.5em;
					@include max-screen-size(medium) {
						display: none;
					}
				}

				.mobile-header {
					display: none;
					width: auto;
					min-width: 4em;
					align-items: center;

					.link-container {
						@include max-screen-size(medium) {
							width: auto;
							min-width: 4em;
						}
					}

					.caret {
						height: 2em;
						width: 2em;
					}

					@include max-screen-size(medium) {
						display: flex;
					}

					&:hover, &:focus {
						color: $dropdown-hover-color;
						background: $header-dropdown-color;

						#caret_modal_opener {
							background: $header-dropdown-color;
							fill: $edlio-red;
						}
					}
				}
			}

			#desktop-multisite-menu {
				@include max-screen-size(small) {
					display: none;
				}
			}

			.menu {
				position: absolute;
				right: 0;

				// hide dropdown menus by default
				@include hide;
				max-height: 20em;
				overflow-y: auto;

				min-width: 100%;
				padding: .382em 0 .618em;

				box-shadow: 0px 4px 4px 0px $default-drop-shadow-color;
				white-space: nowrap;

				@include min-screen-size(medium) {
					right: auto;
					left: 0;
				}

				a {
					padding: 0 1.382em 0 1em;
					font-size: 16px;
					display: flex;
					align-items: center;

					line-height: 3em;
					color: $dropdown-font-color;

					svg.icon {
						width: 1.5em;
						height: 1.5em;
						margin-right: .618em;

						fill: $dropdown-font-color;
						background-color: transparent;
						border: none;
					}

					&:hover {
						background: $dropdown-bg-color;
						box-shadow: inset .382em 0 0 0 $dropdown-hover-color;

						color: $dropdown-hover-color;
						text-decoration: none;

						svg.icon {
							fill: $dropdown-hover-color;
						}
					}
				}
			}

			&:hover {
				span,
				h1 {
					color: $dropdown-hover-color;
				}

				svg.icon {
					fill: $dropdown-hover-color;
				}

				header {
					background: $header-dropdown-color;
					color: $dropdown-hover-color;
				}

				.menu {
					position: absolute;
					@include show(block);
					height: auto;

					background: $header-dropdown-color;
				}
			}
		}

		.homepage-link,
		.churnzero-success,
		.notification-center {
			@include flexbox;
			position: relative;
			margin: 0;

			> .opener {
				@include flexbox;
				@include align-items(center);
				position: relative;
				cursor: pointer;

				&.disabled {
					color: $grey-3;
					pointer-events: none;

					.icon {
						fill: $grey-3;
					}
				}

				span {
					@include hide;

					@include min-screen-size(medium) {
						@include show;
					}
				}

				.badge {
					@include flexbox;
					@include justify-content(center);
					@include align-items(center);

					position: absolute;
					top: .79365em;
					left: 2.55em;
					width: 1.618em;
					height: 1.618em;
					font-size: .7em;
					color: $white;
					background-color: $edlio-red;
					border-radius: 50%;

					&.hidden {
						display: none;
					}
				}

				.icon {
					height: 1.5em;
					width: 1.5em;
					fill: $header-link-color;

					@include min-screen-size(medium) {
						margin-right: .618em;
					}
				}

				&:hover,
				&.active {
					background-color: $white;
					color: $edlio-red;
					text-decoration: none;

					.badge {
						color: $edlio-red;
						background-color: $white;
						box-shadow: inset 0 0 2px $edlio-red;
					}

					.icon {
						fill: $edlio-red;
					}

				}
			}

		}

		#homepage_link {
			display: grid;
			grid-template-columns: 28px 1fr 28px;
			grid-gap: 0.5em;

			.icon-container {
				background-color: $white;
				border-radius: 2px;
				width: 28px;
				height: 28px;
				display: flex;
				align-items: center;
				justify-content: center;

				> svg {
					fill: $red;
				}
			}

			svg:last-child {
				margin: auto;
			}

			@include max-screen-size(medium) {
				grid-template-columns: 1fr;

				.homepage-label,
				svg:last-child:not(.homepage-icon) {
					display: none;
				}
			}
		}

		#churnzero-success-opener.hidden {
			display:none;
		}

		.multisite-menu {
		    max-height: fit-content !important;
				display: flex;
		}

		.multisite-message {
				padding: 0 1em 1em;
				line-height: 1.5em;
				color: $grey-n07;
				font-style: italic;
				display: block;
				text-wrap: wrap;
				font-size: 16px;
		}

        .multisite-search-bar-container {
            display: flex;
            flex-direction: column;
            min-width: 26em;

            .multisite-search-bar-title {
                line-height: 1.5em;
                color: $soft-black;
                font-family: sans-serif;
                font-weight: bold;
                font-size: 17px;
                padding: 0 1em;
                margin: 0;
            }

            .multisite-search-bar {
                display: flex;
                align-items: center;
                padding: 0 1em;
                margin-bottom: 1em;

                .search {
                    display: flex;
                    min-height: 2.5em;

                    .search-field {
                        width: 18em;
                        margin: 0;
                        min-height: 2.2em;
                        font-size: 17px;
                        padding: 0 0.70119rem;
                    }

                    .clear-button {
                        position: absolute;
                        right: 11em;
                        top: 2.5em;
                        width: 3em;
                        height: 3.3em;
                        padding: 0;
                        background: none;
                        border: none;

                        > svg {
                            fill: $soft-black;
                            width: 1.7em;
                            height: 1.7em;
                        }
                    }
                }

                .search-button {
                    background: white;
                    border: 1px solid $grey-n07;
                    border-radius: 0.3em;
                    height: 3em;
                    width: 3em;
                    align-self: start;
                    margin-left: 0.5em;
                    min-height: 2.5em;
                    min-width: 3.3em;

                    &:hover {
                        background: $grey-n01;
                    }

                    > svg {
                        fill: $soft-black;
                        width: 1.7em;
                        height: 1.7em;
                    }
                }
            }
        }

        .multisite-links-container {
            a {
                padding: 0 1.382em 0 1em !important;
            }
        }
	}

	.sangha {

		height: $header-line-height;

		&:hover {
			text-decoration: none;
		}

		.icon {
			&.next,
			&.large {
				@include hide;

				@include min-screen-size(medium) {
					@include show(inline);
				}
			}
			&.small {
				width: 2em;
				height: 2em;
				@include min-screen-size(medium) {
					@include hide;
				}
			}
			&.large {
				height: 100%;
				width: 10em;
			}
			&.next {
				fill: $blue;
			}
		}
	}

	.tip {
		position: absolute;
		top: 4.5em;
		left: 3.1em;
		box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
		background: $white;
		z-index: $z-index-front;
		padding: 1.66667em;
		max-width: 20em;
		color: $grey-c08;
		text-align: center;
		@include font-size(normal);

		@include max-screen-size(medium) {
			@include font-size(large);
			left: 1em;
			top: 4em;
		}

		&.sangha {
			left: unset;
			right: 3.1em;
			height: auto;
			text-align: left;

			@include max-screen-size(medium) {
				left: unset;
				right: 1em;
			}

			.tail {
				left: unset;
				right: 80px;

				@include max-screen-size(medium) {
					right: 25px;
				}
			}

			p {
				line-height: 1.3em;
			}
		}

		.tail {
			position: absolute;
			width: 38px;
			height: 20px;
			overflow: hidden;
			top: -20px;
			left: 17px;
		}

		.tail:before {
			content: "";
			position: absolute;
			left: 9px;
			top: 12px;
			width: 20px;
			height: 18px;
			background: $white;

			@include rotate(45);

			box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
		}

		.intro {

			@extend %flexbox;
			@include flex(1, 0, auto);
			@include align-items(center);
			@include justify-content(center);

			margin-bottom: 1em;

			svg.spike {
				height: 2.778em;
				width: 3.667em;
				background: $edlio-red;
				fill: $white;
				border-radius: $default-border-radius;
				margin-right: 1em;
			}

			p {
				margin: 0;
				@include font-size(large);

				@include max-screen-size(medium) {
					@include font-size(larger);
				}
			}

		}

		p:last-of-type {
			margin-bottom: 0;
		}

		.close.icon {
			position: absolute;
			top: 10px;
			right: 10px;
			height: 1em;
			width: 1em;
			fill: $grey-n04;
			cursor: pointer;
			pointer-events: all;

			&:hover {
				fill: rgb(29, 156, 211);
			}
		}

	}
}

	.dws-modal {
		.header {
			display: flex;
			justify-content: space-between;
			color: #d22630;
			padding-top: 0.5em;

			h1 {
				margin: 0 0 1rem;
			}
		}

		.cancel {
			width: 38px;
			height: 38px;
			cursor: pointer;
		}

		.multisite-menu {
			max-height: 26em;
			overflow-y: scroll;
		}

		.multisite-search-bar-container {
			display: flex;
			flex-direction: column;

			.multisite-search-bar-title {
				line-height: 1.5em;
				color: $soft-black;
				font-weight: bold;
				font-size: 18px;
			}

			.multisite-search-bar {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.search {
					display: flex;
					position: relative;
					border: 1px solid $grey-n07;
					align-items: center;
					width: 100%;

					.clear-button {
						position: static !important;
						width: 3.5em;
						height: 3.5em;
						padding: 0;
						background: none;
						border: none;

						> svg {
							fill: $soft-black;
							width: 1.7em;
							height: 1.7em;
						}
					}
				}

				.search-field {
					margin-bottom: 1rem;
					height: 2.5em;
					border: none;
					background: transparent;
					width: 100% !important;
				}

				.search-button {
					background: white;
					border: 1px solid $grey-n07;
					border-radius: 0.3em;
					height: 3.4em !important;
					width: 3.4em !important;
					align-self: start;
					margin-left: 0.5em;

					&:hover {
						background: $grey-n01;
					}

					> svg {
						fill: $soft-black;
						width: 1.7em;
						height: 1.7em;
					}
				}
			}
		}


		#modal-multisite-links-container {
			display: flex;
			flex-direction: column;
			overflow-y: scroll;

			.link-item {
				padding-top: 0.5em;
				padding-left: 0.5em;
			}
		}

		.multisite-message {
			padding: 1em 0 1em;
			line-height: 1.5em;
			color: $grey-n07;
			font-style: italic;
			display: block;
			text-wrap: wrap;
			font-size: 16px;
		}

		footer {
			justify-content: flex-start !important;
			margin-bottom: 60px;
			margin-top: 2em !important;

			a {
				text-decoration: none;
				color: $dropdown-font-color !important;
			}

			.manage-website {
				align-items: center;
				display: flex;

				&:hover, &:focus {
					color: #d22630 !important;
					.icon {
						fill: #d22630;
					}

				}
				.connect-label {
					padding-left: 0.5em;
				}
			}

		}
	}