/* This brings legacy styles into new feature pages */

/* Log out message*/
.no-scroll-expired-session {
	overflow: hidden;
}

.overlay.expired-session {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.85);
	z-index: 1999999999;
	display: none;
}

.overlay.expired-session .modal-box {
	font-family: Arial, san-serif;
	text-align: left;
	position: fixed;
	top: 50%;
	left: 50%;
	background: white;
	border-radius: 3px;
	box-shadow: 0 0 6px rgba(0,0,0,.6);
	z-index: 1999999999;
	-webkit-font-smoothing: antialiased;
}

.overlay.expired-session .modal-session-alert.modal-box {
	text-align: center;
	width: 330px;
	margin-left: -165px;
	height: 150px;
	margin-top: -75px;
}

.overlay.expired-session .modal-box h1 {
	color: #000;
	font-size: 20px;
	line-height: 36px;
	font-weight: bold;
	margin: 0 0 .8em;
	background-color: #f0f0f0;
	border-radius: 3px 3px 0 0;
	text-align: center;
}

.modal-box #session_extend_link {
	display: block;
	font-weight: bold;
	height: 30px;
	line-height: 28px;
	margin: 15px auto 0;
	text-decoration: none;
}

.modal-box #session_extend_link {
	font-size: 1.02em;
	width: 150px;
}

.modal-box #session_extend_link:active {
	color: #000;
}

.modal-box #modal_login_submit {
	display: inline-block;
	margin-top: .2em;
	font-size: 1.2em;
}

#modal_login_form #modal_login_submit {
	text-align: center;
}

.overlay.expired-session .modal-login.modal-box {
	width: 360px;
	margin-left: -180px;
	margin-top: -148px;
}

.overlay.expired-session .modal-login.has-error-message {
	margin-top: -179px;
}

.modal-box .modal-content {
	padding: 0 16px 16px;
	font-size: 11.5pt;
	line-height: 1.34;
	color: #333;
}

.modal-box .modal-content p {
	margin: 10px 0;
}

.modal-box .modal-content .descriptive-text{
	margin-top: 10px;
	margin-bottom:  25px;
}

.modal-box .modal-content .descriptive-label {
	color: #444444;
	font-size: 13px;
	margin-bottom: 2em;
}

.modal-login.modal-box ul {
	margin: 0;
	list-style: none;
	padding: 0;
}

.modal-login.modal-box li {
	margin: 0 0 5px;
	padding: 0;
}

.modal-login input::-webkit-outer-spin-button,
.modal-login input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.modal-login input[type="number"] {
	-moz-appearance:textfield; /* Firefox */
}

.modal-login.modal-box li label {
	font-size: 14px;
	display: block;
	text-align: left;
	margin-bottom: 5px;
	font-weight: bold;
}

.overlay.expired-session .login-error {
	display: none;
	background-color: #f8cdcb;
	padding: 8px;
	font-size: .9em;
}

.overlay.expired-session .recaptcha-branding {
	margin-top: 5px;
	font-size: x-small;
}

.overlay.expired-session .recaptcha-error {
	display: none;
	background-color: #f8cdcb;
	padding: 8px;
	font-size: .9em;
}

.overlay.expired-session #modal_login_form input:disabled {
	background-color: #f0f0f0;
}

.overlay.expired-session #modal_login_form input {
	width: 318px;
	line-height: 20px;
	font-size: 16px;
	margin-bottom: 0;
	padding: 2px;
}

.overlay.expired-session #modal_login_form input#password:focus {
	box-shadow:	0 0 5px rgba(18,65,129,.3), inset 0 0 15px rgba(0,0,0,.03);
}

#modal_login_form #not_me_link {
	color: $blue;
	text-decoration: underline;
	font-size: 14px;
}

/* see: http://docs.angularjs.org/api/ng.directive:ngCloak; adding this here in case angular.js isn't
        loaded in the head
*/
[ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
	display: none !important;
}

[ng-click], [data-ng-click], [x-ng-click], .ng-click, .x-ng-click {
	cursor: pointer;
}

/* override default Google Drive picker modal overlay cover */
.picker.modal-dialog-bg.picker-dialog-bg {
	background-color: #000;
	opacity: 0.6 !important;
	z-index: 2147483630 !important;
}

.picker.modal-dialog.picker-dialog {
	z-index: 2147483631 !important;
}



/* Maintenance message */
#maintenance_message_wrapper {
	width: 100%;
	padding: 20px 0;
	background-color: #aedddc;
	position: relative;
	z-index: 1;
	text-align: center;
	font-size: 1.2em;
	border-bottom: 1px solid #176967;
	margin-bottom: 0;
}

#maintenance_message_wrapper div {
	max-width: 850px;
	margin: 0 auto;
}

#maintenance_message_wrapper #maintenance_icon {
	width: 35px;
	height: 30.53px;
	fill: #176967;
	display: inline-block;
	vertical-align: middle;
}

#maintenance_icon {
	float: left;
}

#maintenance_message_wrapper .warning-message {
	margin-left: 10px;
}


/*** Pretty button - from Bell schedules ***/

button.pretty-button {
	overflow: visible; /* removes extra side padding in IE */
}

button.pretty-button, a.pretty-button {
	cursor: pointer;
	position: relative;
	padding: 0 16px;
	font-size: 10pt;
	font-family: Arial, serif;
	color: #333;
	text-decoration: none;
	text-align: center;
	text-shadow: .1em .1em 1px rgba(255,255,255,.8);
	line-height: 24px;
	max-width: 290px;
	display: inline-block;
	white-space: nowrap;
	background: rgb(250,250,250);
	background: -moz-linear-gradient(top,  rgba(250,250,250,1) 0%, rgba(217,217,216,1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(250,250,250,1)), color-stop(100%,rgba(217,217,216,1)));
	background: -webkit-linear-gradient(top,  rgba(250,250,250,1) 0%,rgba(217,217,216,1) 100%);
	background: -o-linear-gradient(top,  rgba(250,250,250,1) 0%,rgba(217,217,216,1) 100%);
	background: -ms-linear-gradient(top,  rgba(250,250,250,1) 0%,rgba(217,217,216,1) 100%);
	background: linear-gradient(to bottom,  rgba(250,250,250,1) 0%,rgba(217,217,216,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#d9d9d8',GradientType=0 );
	border-radius: 4px;
	border: 1px solid #8e8e8e;
	box-shadow: 1px 1px 0 rgba(0,0,0,.05), 0 0 1px rgba(255, 255, 255, 0.5) inset;
	z-index: 9;
}

a.pretty-button {
	height: 24px;
}

button.pretty-button {
	height: 26px;
	line-height: 24px;
}

button.pretty-button::-moz-focus-inner {
	border: none;  /* overrides extra padding in Firefox */
	padding: 0;
}

.pretty-button.primary {
	font-weight: bold;
	float: left;
	padding: 0 26px;
}

/* For whatever stupid reason IE needs it so text is not shifting on click */
button.pretty-button span,
a.pretty-button span {
	display: block;
	position: relative;
}

.pretty-button:active,
.pretty-button:focus { /* Need this for IE */
	background: rgb(208,208,208);
	background: -moz-linear-gradient(top,  rgba(208,208,208,1) 0%, rgba(244,244,244,1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(208,208,208,1)), color-stop(100%,rgba(244,244,244,1)));
	background: -webkit-linear-gradient(top,  rgba(208,208,208,1) 0%,rgba(244,244,244,1) 100%);
	background: -o-linear-gradient(top,  rgba(208,208,208,1) 0%,rgba(244,244,244,1) 100%);
	background: -ms-linear-gradient(top,  rgba(208,208,208,1) 0%,rgba(244,244,244,1) 100%);
	background: linear-gradient(to bottom,  rgba(208,208,208,1) 0%,rgba(244,244,244,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0d0d0', endColorstr='#f4f4f4',GradientType=0 );
}

/* For stupid IE */
.pretty-button:active,
.pretty-button:focus,
.pretty-button:visited {
	outline: none;
}

/*** Verify button  ***/

button.verify-button {
	overflow: visible; /* removes extra side padding in IE */
}

button.verify-button, a.verify-button {
	cursor: pointer;
	position: relative;
	padding: 0 32px;
	font-size: 16px;
	font-family: Arial, serif;
	color: $white;
	text-decoration: none;
	text-align: center;
	max-width: 290px;
	display: inline-block;
	white-space: nowrap;
	background: $blue;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#d9d9d8',GradientType=0 );
	border-radius: 4px;
	border: 1px solid $blue;
	box-shadow: 1px 1px 0 rgba(0,0,0,.05), 0 0 1px rgba(255, 255, 255, 0.5) inset;
	z-index: 9;
}

a.verify-button {
	height: 44px;
}

button.verify-button {
	height: 44px;
	line-height: 24px;
}

button.verify-button::-moz-focus-inner {
	border: none;  /* overrides extra padding in Firefox */
	padding: 0;
}

.verify-button.primary {
	font-weight: bold;
	float: left;
	padding: 0 26px;
}

/* For whatever stupid reason IE needs it so text is not shifting on click */
button.verify-button span,
a.verify-button span {
	display: block;
	position: relative;
}

.verify-button:active,
.verify-button:focus { /* Need this for IE */
	background: $blue;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0d0d0', endColorstr='#f4f4f4',GradientType=0 );
}

/* For stupid IE */
.verify-button:active,
.verify-button:focus,
.verify-button:visited {
	outline: none;
}

//hide tinymce toolbar when menu is opened
.tox-silver-sink {
	z-index: 97;
}

.tox .tox-dialog-wrap__backdrop {
	background-color: $overlay-color;
}

.grecaptcha-badge {
	visibility: hidden;
}

body {
	&[data-loading]:before {
		content: "";
		width: 100%;
		height: 100vh;
		background-color: $overlay-color;
		display: inline-block;
		position: fixed;
		z-index: 10000;
	}

	&[data-loading]:after {
		content: attr(data-loading);
		position: fixed;
		width: 100%;
		top: 50vh;
		z-index: 10001;
		display: block;
		text-align: center;
		font-size: 23px;
		font-weight: bold;
		color: white;
	}

	&[data-loading*="uploading"]:after {
		background-image: url(/apps/svg/spike-loading.svg);
		background-repeat: no-repeat;
		background-position-x: calc(50% - 100px);
		line-height: 42px;
	}

	&[data-loading*="failed"]:after {
		color: $orange;
	}
}
