// Edlio Footer

.powered-by.footer {
	display: block;
	margin: 1em auto;
	width: 8.159em;
	height: 1.2em;
	overflow: hidden;
	text-decoration: none !important;
	clear: both;

	 svg {
	 	width: 8.159em;
		height: .918em;
		fill: #535d67;
	 }
}
