.bar {
	display: block;
	margin: 0;
	padding: {
		top: 1em;
		bottom: 1em;
	}

	border-bottom: 1px solid $dark-grey;

	&.tab {
		padding: {
			top: 0;
			bottom: 0;
		}

		a.selected {
			cursor: default;
		}
	}

}
